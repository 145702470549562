<template>
  <div class="main">
    <div class="top">
      <div class="back" @click="onClickLeft('/')">
        <img src="../assets/img/back.png" alt="" />
      </div>
      <div class="title">
        {{$t('m.ystz')}}
      </div>
      <div class="navbtn" @click="onClickLeft('/activere')">
      {{$t('m.wdtz')}}
      </div>
    </div>
    <div class="list">
      
      <div class="block" v-for="(item,i) in list" :key="i"   @click="menu(item)">
    <div class="item fadeInUp"  v-if="item.showStatus==1">
      <div class="card" >
        <div class="star" >{{item.status==1?$t('m.jxz'):$t('m.yjs')}}</div>
      <div class="tops">
          <img src="../assets/img/logow.png" alt="" class="nft">      
          <div class="ltxt">{{dqyy =='en-US'?item.nameEn:item.name}}</div>
        </div>
      <div class="yqlink">
       
      </div>
    <div class="sbottom fadeInUp" >
      <div class="lines"><span>{{$t('m.suanli')}}：</span>  <span>{{item.ratio}}</span>  CS</div> 
            <div class="lines"><span>{{$t('m.jiage')}}：</span>  <span>{{item.price}}</span>  USDT</div>           
            <div class="lines"><span>{{$t('m.zongsu')}}：</span>  <span>{{item.totalAmount-item.araAmount}} / {{item.totalAmount}} </span>{{$t('m.fen')}} </div>            
               <div class="lines"><span>{{$t('m.zhouqi')}}：</span>  <span>{{item.cycle}} </span> {{$t('m.day')}}</div> 
          </div>  
            <div class="box">
                <div class="txt">{{$t('m.xmjd')}} ({{((item.araAmount/item.totalAmount)*100).toFixed(2)}}%)</div>
                  <van-progress :percentage="((item.araAmount/item.totalAmount)*100).toFixed(2)"  color="linear-gradient(to right, #266ffe, #7232dd)" stroke-width="8" />
              </div>     
    </div>
    </div>
  </div>
  
</div>
 
  </div>
</template>

<script>
import { upnode,getMyUsdt,transferHt} from "@/api/Public";
export default {
  data() {
    return {
      userWallet: "",
      codeWallet: "",
      userInfo:'',
      list: [],
      day:10,
      total: 0,
      page: 1,
      loading: false,
      finished: false,
      refreshing: false,
      menuShow: false,
      pType: 0,
      type: '',
      ptype:1,
      myAmount:0,
      Amount: 0,
      price: 0,
      bsn: "",
      bk: "",
      cake: "",
      base: "",
      fate:1,
      value:0,
      amountU:0,
      file:[      ],
      gpList:[
      ],
      info:0,
      teamRadio:0,
      pAmount:'',
      qydata:{},
      first:''
    };
  },
  computed: {
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "zh-CN";
      }
    },
  },
  filters: {
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{8})\w+(\w{8})/, "$1...$2");
        return a;
      } else {
        return "";
      }
    },
  },
  watch: {
    "$store.getters.userWallet": function (val) {
      this.onClickLeft("/");
    },
    deep: true,
    immediate: true,
  },
  created() {
    this.userWallet = this.$store.getters.userWallet;
    this.getBase();
    // this.getlimt();
    this.getFate()
    this.getlist();
  },
  methods: {
    getFate(){
      getMyUsdt(this.$store.getters.userWallet).then(res=>{
        // console.log(res);
        this.myAmount=res
      })
      this.$get({
        url: "wms-wallet/getWallet",
        data:{ trxAddress:this.userWallet},
        callback: (res) => {
          this.$nextTick(function () {
              this.Amount=res.data.fgAmount
          })
        }
       })
    },
      topType(e){
          if(!this.loading){
            this.jsnum()
          this.ptype=e}
          
          },
    getBase(){
       this.$get({
        url: "sms-base/getFate",
        data:{},
        callback: (res) => {
          this.$nextTick(function () {
              this.fate=res.data
          })
        }
       })
    },
    getlimt() {
      this.$get({
        url: "plat-history/getDay",
        data: { uid: this.$store.getters.myID },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.base = res.data;
            }
          });
        },
      });
    },
    async getlist(){
      
     this.onLoad()
      
    },
    async toType(e) {
      this.type = e.usdt;
      //  this.$get({
      //   url: "ums-product/getList1",
      //   data: {price:e.usdt },
      //   callback: (res) => {
      //     this.$nextTick(function () {
      //       if (res.data) {
      //         this.gpList = res.data;
      //         this.type = e.usdt;
      //         this.Amount=e
      //       }
      //     });
      //   },
      // });
      
    },
    async toMax() {
      if (this.loading) {
        return;
      }
      await this.getlimt();
      this.Amount = this.base.info3;
    },


    async getPrice() {
      this.loading = true;
      this.$get({
        url: "sms-base/getRate",
        data: {},
        callback: (res) => {
          this.$nextTick(function () {
            if (this.type == 1) {
              this.price = res.data.bking;
            }
            if (this.type == 2) {
              this.price = res.data.cake;
            }
            if (this.type == 3) {
              this.price = res.data.bnb;
            }
            this.loading = false;
          });
        },
      });
    },

    onClickLeft(val) {
      if (this.loading) {
        this.$notify(this.$i18n.t("m.wxtswtjxz"));
        return;
      } else {
        this.$router.replace({ path: val });
      }
    },
    menu(e) {
    if(e.status==1){
      sessionStorage.setItem('film',JSON.stringify(e))
      this.onClickLeft('/Detail')
    }else{
      this.$notify(this.$i18n.t("m.qxztzed"));
    }
        // if(this.pAmount>0){
      //      this.$get({
      //  url: "ums-movie-history/check",
      //   data:{ mid:e.id,uid:this.$store.getters.myID,},
      //   callback: (res) => {
      //     this.$nextTick(function () {
      //       if (res.data>=0) {
      //         this.qydata=e
      //         this.value=res.data
      //         this.menuShow = true; 
      //       }else{
      //         this.$notify(this.$i18n.t("m.nyrg"));
      //       }
      //     });
      //   },
      // });
          
        // }else{
        //      this.$notify(this.$i18n.t("m.qxztzed"));
        // }
    },
    gbsmenu() {
      this.menuShow = false;
    },
    toJoin(){
     if(this.pAmount-this.qydata.minAmount>0){
        let amount=(this.pAmount*1).toFixed(4)
        if(amount>0){
             this.loading=true
             if(this.ptype==1){
      upnode(this.$store.getters.userWallet,amount).then(trxId=>{
        // console.log(trxId,'trxId');
        //  trxId='0xa67cbb1582b8f3c4d069ba1e82370ff665b8bf248177ec5c85fb6761556ea8be'
        if(trxId){         
        let data={ uid: this.$store.getters.myID,
          amount:amount,
          amountU:this.amountU,
          trxId:trxId,
          name:this.qydata.name,
          nameEn:this.qydata.nameEn,
          type:this.ptype,
          currency:this.ptype,
          mid:this.qydata.id,
          trxAddress:this.$store.getters.userWallet
        }
        this.Updata(data);
        
                
        }else{
          this.loading=false
            this.$notify(this.$i18n.t("m.qxrg"))
        }
      })
             }

             if(this.ptype==2){
              transferHt(this.userWallet).then(res=>{
                if(res){
                  let data={
                     uid: this.$store.getters.myID,
          amount:this.amountU,
          amountU:amount,
          trxId:res,
          name:this.qydata.name,
          nameEn:this.qydata.nameEn,
          type:this.ptype,
          currency:this.ptype,
          mid:this.qydata.id,
          trxAddress:this.$store.getters.userWallet
                  }
                   this.Updata(data);
                }
              })
             }

        }
     }else{
      this.$notify($t('m.zxtzed')+this.qydata.minAmount+'USDT')
     }
  
    },
    Updata(data){
 this.$post({
        url: "ums-movie-history/createHistory",
        data: data,
        callback: (res) => {
          this.$nextTick(function () {
            this.loading=false
            if (res.data) {
               this.$notify({
                message: this.$i18n.t("m.rgcg"),
                type: "success",
              });
              this.getFate()
              this.pAmount={}
              this.menuShow=false
            }else{
              this.$notify(this.$i18n.t("m.rgsb"))
            }
          })}
                 })
    },
    jsnum(){
      setTimeout(() => {
        if(this.ptype==1){
          let max= this.myAmount-this.qydata.maxAmount>0?this.qydata.maxAmount:this.myAmount
          this.pAmount=max-this.pAmount>0?this.pAmount:max
          this.amountU=this.pAmount
        }
        if(this.ptype==2){
           let max= this.Amount-this.qydata.maxAmount>0?this.qydata.maxAmount:this.Amount
           this.pAmount=max-this.pAmount>0?this.pAmount:max
           this.amountU=(this.pAmount/this.fate).toFixed(4)

        }
      }, 100);
    
    },
    tochange(e){
      if(this.loading){return}
      this.pAmount=e
    },
    _pay(haxi, amount, type) {
      this.$post({
        url: "plat-history/createHistory",
        data: {
          trxAddress: this.userWallet,
          uid: this.$store.getters.myID,
          price:amount,
          type: type,
          trxId: haxi,
        },
        callback: (res) => {
          this.$nextTick(function () {
            this.loading = false;
            if (res.data) {
              this.$notify({
                message: this.$i18n.t("m.cycg"),
                type: "success",
              });
        this.menuShow=false
            } else {
              this.$notify(this.$i18n.t("m.cysb"));
            }
          });
        },
      });
    },
    goto(val) {
      this.$router.push({ path: val });
    },
    changer(val) {
      if (val == "en-US") {
        val = "zh-CN";
      } else {
        val = "en-US";
      }
      this.$i18n.locale = val;
      this.$store.commit("language", val);
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
  to(url) {
       window.open(url)
    },
    onLoad() {
      if (this.page == 1) {
        this.list = [];
        this.refreshing = false;
      }

      this.$get({
        url: "ums-product/getProductList",
        header: this.$store.getters.userWallet,
        data: {
          pageNum: this.page,
          pageSize: 10,
        },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.loading = false;
              if (this.page > 1) {
                this.list = [...this.list, ...res.data];
              } else {
                this.list = res.data;
                  // this.list = [...this.list, ...res.data];
              }
                let first=this.list[0]
                this.$delete(this.list,0)
                // let fist= el
                this.list.reverse()
                this.list.unshift(first)
              if (res.data.length >= 10) {
                this.page++;
              } else {
                this.finished = true;
              }
            }
          });
        },
      });
    },

    
    onCopy() {
      this.$toast("复制成功");
    },
    onError() {
      this.$toast("复制失败");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";
$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";
.main {
  height: 100%;
  min-height: 100vh;
  width: 750px;
  margin: 0 auto;
      display: flex;
    flex-direction: column;
 background:  #161628 url("../assets/img/bg50.png")  fixed no-repeat;
  // background: #0F112A;
  background-size:cover;
  background-position: top center;
  position: relative;
  ::v-deep.van-nav-bar__content {
    height: 80px !important;
  }
  ::v-deep.van-nav-bar__arrow {
    font-size: 36px;
  }
  ::v-deep.van-ellipsis {
    font-size: 32px;
    line-height: 44px;
  }
 .top {
    background: transparent;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 28px;
    height: 96px;
    margin: 0 auto;
    color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #656262;
    .back {
      position: absolute;
      left: 20px;
      width: 50px;
      height: 50px;
      //   background: linear-gradient(45deg,#e4005e, #7C58D2);
      // border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 50px;
        width: 50px;
      }
      .icosn {
        height: 36px;
        margin-right: 5px;
        width: 36px;
      }
    }
    .title {
      background: linear-gradient(234deg, #ffffff, #ffffff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 36px;
      font-weight: 600;
      font-family: $t-f;
    }
    .navbtn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 150px;
      position: absolute;
    right: 28px;
    font-size: 24px;
        font-family: $t-mf;
        font-weight: 600;
        color: #fff;
        line-height: 42px;
        white-space: nowrap;
      .img {
        width: 36px;
        height: 30px;
      }
      .img2 {
        width: 36px;
        height: 36px;
        margin: 0 36px;
      }
      .lang {
        font-size: 22px;
        font-family: $t-mf;
        font-weight: 600;
        color: #fff;
        line-height: 42px;
        // margin-left: 20px;
      }
    }
  }
  .list{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .block{
    width: 100%;
  }
  .item{
 background: rgb(5 3 15) url('../assets/img/bg21.jpg') no-repeat;
        background-size: cover;
           width: calc(90% - 22px);
               margin: 5% auto;
                 box-shadow: 0px 8px 6px rgb(0 0 0);
  }
  .card {
    // background: rgb(5 3 15) url('../assets/img/abg.png') no-repeat;
   position: relative;
   background: #0000009e;
  //         backdrop-filter: blur(5px);
  // -webkit-backdrop-filter: blur(5px);
    border-radius: 0px;
    padding: 10px;
    box-sizing: border-box;
         border: 1px solid;
border-image: linear-gradient(267deg, rgba(36, 100, 254, 1), rgba(43, 150, 255, 1), rgba(34, 173, 240, 1), rgba(34, 206, 219, 1), rgba(30, 209, 179, 1), rgba(30, 224, 148, 1), rgba(31, 224, 120, 1)) 1 1;

.star{
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(234deg, #e4005e, #7C58D2);
  color: #ffffff;
  border-radius:0 0 0px 25px ;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;

}
    // border: 1px solid #7C58D2;
    .tops{
          display: flex;
          justify-content:flex-start;
          align-items: center;
          position: relative;
          // margin: 48px;
          width:100% ;
          height: auto;
          overflow: hidden;
          
          text-shadow: 0px 2px 15px rgba(0,0,0);
          .nft{
            width:106px;
            height:106px;
            background: #ffffff;
              // border: 1px solid  #7C58D2;
              margin: 16px;
              border-radius: 10%;
            box-shadow: 0px 8px 6px rgb(16 16 16 / 37%);
              backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
          }
           .ltxt {
        font-size: 36px;
        font-family: $t-f;
        font-weight: 600;
        color: #ffffff;
        text-align: left;
        width: 100%;
        margin:  4px;
      }
    }
    .logo {
      width: 250px;
    }
    .yqlink {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 18px;
     
      .mylink {
        width: 440px;
        height: 60px;
        background: #fff;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 22px;
        .address {
          width: 250px;
          height: 32px;
          font-size: 22px;
          font-family: $t-mf;
          font-weight: 600;
          color: #000000;
          line-height: 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .fuzhi {
          background: transparent;
          border: none;
          width: 50px;
        }
        .icon {
          width: 42px;
          height: 42px;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      // background: #f8f8f8;
      margin: 10px 0;
    }

    .bg {
      // background: linear-gradient(234deg, #e4005e, #7C58D2);
      margin: 20px auto;
      padding: 15px;
      position: absolute;
      width: 100%;
      height: 100%;
      // box-shadow: 0 10px 5px rgb(10 10 10 / 54%);
    }
    .flex {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      .act {
        // border: 5px solid #EF155D;
        // border-radius: 10px;
        color: #ffffff;
            background: rgb(25 0 22 / 51%);
    box-shadow: 2px 2px 0.04rem 0px #131418;
        // background:#0800195e;
        //  box-shadow:  inset 2px 2px 3px 0px #131418, inset -2px -2px 3px 0px #474853;
      }
    }
    .box{
      // min-height: 300px;
      width: 100%;
      margin: 12px 0 30px;
       padding:0 20px;
      box-sizing: border-box;
       .txt {
          font-size: 26px;
          font-family: $t-mf;
          font-weight: 400;
          color: #ffffff;
          line-height: 50px;
          text-align: left;
            margin: 12px 0;
        }
    }
    .tips {
        flex-grow: 1;
        border: 5px solid transparent;
      font-size: 30px;
      font-family: $t-f;
      font-weight: 400;
      color: #333333;
      text-align: left;
      padding: 20px;
      background: rgb(255 255 255 / 100%);
      display: flex;
      align-items: center;
      overflow: hidden;
      flex-direction: column;
      margin: 20px 0;
    width: calc(100% - 38px);
    justify-content: flex-start;
    box-sizing: border-box;
    .stop{
         display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    }
    .sbottom{
      // width: 100%;
       padding: 0 20px;
       white-space: nowrap;
      .lines{
        margin: 10px 0;
          display: flex;
      align-items: center;
      justify-content: space-between;
          border-bottom: 1px solid #ffffff33;
    padding-bottom: 10px;
    color: #ffffff;
      span:nth-child(2){
        color: #0af3ef;
        display: block;
        width: calc(100% - 22px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: right;
        margin-right: 15px;
        font-weight: bold;
      }
      }
    }
      .imgs {
        height: 48px;
        width: 48px;
        margin: 10px;
      }
      input {
        border: none;
        background: transparent;
        height: 56px;
        flex: 1;
      }
      .max {
        width: 100px;
        height: 50px;
        background: #262626;
        border-radius: 10px;
        margin: 10px;
        color: #fff;
        text-align: center;
        line-height: 50px;
        font-size: 22px;
      }
    
    .ye {
      margin: 20px 34px;
      padding: 5px;
      font-size: 18px;
      white-space: nowrap;
      text-align: left;
      color: #ffffff;
      font-weight: 600;
      font-family: $t-f;
    }
    .btns {
      width: 638px;
      height: 80px;
      background: #000000;
      border-radius: 40px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 18px auto 20px;
    }
  }
  .lists {
    width: 696px;
    min-height: 252px;
    background: #ffffff;
    border-radius: 20px;
    margin: 0px auto;
    overflow: auto;
    .title {
      font-weight: 600;
      font-size: 28px;
      margin: 20px 32px;
      text-align: left;
    }
    .list-top {
      display: flex;
      align-items: left;
      justify-content: space-between;
      margin: 50px 0 0;
      border-top: 1px solid #f8f8f8;
      border-bottom: 1px solid #f8f8f8;
      padding: 30px 30px 30px;
      div {
        font-size: 24px;
        font-family: $t-mf;
        font-weight: 600;
        color: #8b8b93;
        line-height: 34px;
        text-align: left;
        white-space: nowrap;
        margin: 0 5px;
      }
    }
    .list-item {
      width: 100%;
      div {
        font-size: 24px;
        font-family: $t-f;
        font-weight: 400;
        color: #666666;
        line-height: 34px;
        text-align: left;
        // margin: 0 5px;
        white-space: nowrap;
        flex: 1;
        .fuzhi {
          background: transparent;
          border: none;
          width: 30px;
        }
        .fz {
          width: 28px;
          height: 26px;
          margin: 0 -5px;
        }
      }
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 0px;
        border-bottom: 1px solid #f7f7fa;
        width: 100%;
      }
    }
    .d1 {
      width: 50%;
    }
    .d2 {
      width: 50%;
      text-align: right !important;
    }
  }

  .bottom {
    margin: 44px 0 24px;
    display: flex;
    align-items: center;
    .btn {
      width: calc(100% - 48px);
      // width: 100%;
      height: 80px;

  border: 1px solid;
border-image: linear-gradient(267deg, rgba(36, 100, 254, 1), rgba(43, 150, 255, 1), rgba(34, 173, 240, 1), rgba(34, 206, 219, 1), rgba(30, 209, 179, 1), rgba(30, 224, 148, 1), rgba(31, 224, 120, 1)) 1 1;
background: url("../assets/img/bg21.jpg") no-repeat ;
   background-size: cover ;
    background-position: center ;
      box-shadow: 0px 4px 6px 0px rgb(0,0,0);
        border: 1px solid #ffffff; 
      font-size: 32px;
      border-radius:0px ;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 20px auto;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .stbn {
      font-size: 30px;
      font-family: $t-f;
      font-weight: 600;
      color: #8b8b94;
      margin: 10px auto;
      line-height: 34px;
    }
  }
  .mentop {
    display: flex;
    justify-content: space-between;
    margin: 44px 30px;
    .logo {
      // height: 82px;
      width: 232px;
    }
    .gb {
      height: 40px;
      width: 40px;
      margin-right: 8px;
    }
  }
  .menulist {
    margin-top: 100px;
    .menuitem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 44px;
      border-bottom: 1px solid #f8f8f8;
      padding-bottom: 30px;
      .left {
        flex: 1;
        text-align: left;
        display: flex;
        img {
          height: 48px;
          width: 48px;
        }
        .txt {
          margin-left: 40px;
          font-size: 36px;
          font-family: $t-mf;
          font-weight: 600;
          @include font_color("font_color1");
          line-height: 50px;
        }
      }
      .right {
        img {
          height: 38px;
          width: 26px;
        }
      }
    }
  }
  .buttoms {
    height: 28px;
    font-size: 24px;
    font-family: $t-f;
    font-weight: bold;
    color: #999999;
    line-height: 28px;
    margin: 80px auto 0px;
    padding-bottom: 80px;
    width: 100%;
  }
  .card2{
        width: 652px;
    background: rgba(38, 38, 38, 0.48) url('../assets/img/bm/emit.png') no-repeat;
    //  box-shadow: 0px 4px 16px 4px rgba(62,1,162,1);
        background-size: contain ;
        background-position: center;
    border-radius: 20px;
    padding: 20px;
    min-height: 200px;
    margin: 5% auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .sitem{
         width: calc( 50% - 40px);
    text-align: center;
    background: rgba(0, 0, 0, 0.3411764705882353);
    margin: 10px;
    padding: 10px;
          .ttt{
               font-size: 24px;
        font-family: $t-mf;
        font-weight: 600;
        color:#888888;
        line-height: 40px;
        margin-top: 12px;

          }
          .num{
          margin-top: 22px;
        font-size: 26px;
        font-family: $t-f;
        font-weight: bold;
        background: linear-gradient(234deg,#e4005e, #7C58D2);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        white-space: nowrap;
          }
      }
  }
    .popup {
    width: 672px;
    background: #1B1D46;
    border-radius: 30px;
    ::v-deep.van-list{
      height: 50vh;
    overflow: auto;
    }
    .title {
      font-size: 48px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 66px;
      margin: 44px auto 12px;
    }
    .ttx{
       font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 66px;
      font-size: 26px;
    }

    .bg {
      // background: linear-gradient(234deg, #e4005e, #7C58D2);
      // margin: 20px auto;
      padding: 15px;
      // width: 100%;
      height: 100%;
      // box-shadow: 0 10px 5px rgb(10 10 10 / 54%);
    }
    .box{
      min-height: auto;
    width: auto;
    border: 1px solid #ffffff33;
    // padding: 10px;
    // background: linear-gradient(8deg, #3C2F64, #CE0A54);
 .imgss{
      width: 100%;
      height: auto;
     }
    }
    .txt{ 
      margin: 10px 20px;
      font-size: 26px;
      text-align: left;
      color: #ffffff;
      }
    .flex {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      .act {
        // border: 5px solid #EF155D;
        // border-radius: 10px;
        color: #ffffff;
    //         background: rgb(25 0 22 / 51%);
    // box-shadow: 2px 2px 0.04rem 0px #131418;
        // background:#0800195e;
        //  box-shadow:  inset 2px 2px 3px 0px #131418, inset -2px -2px 3px 0px #474853;
      }
    }
    .tips {
        flex-grow: 1;
        border: 5px solid transparent;
      font-size: 30px;
      font-family: $t-f;
      font-weight: 400;
      color: #333333;
      text-align: left;
      padding: 20px;
      // background: rgb(255 255 255 / 100%);
      display: flex;
      align-items: center;
      overflow: hidden;
      flex-direction: column;
      // margin: 20px 0;
    // width: calc(100% - 38px);
    justify-content: flex-start;
    box-sizing: border-box;
    .stop{
         display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #ffffff33;
      width: 100%;
          box-sizing: border-box;
      padding: 10px 20px;
       margin-bottom: 40px;
      .laber{
        width: 100px;
        white-space: nowrap;
        margin-right: 20px;
      }
      .input{
        text-align: right;
        padding: 10px;
        color: #0af3ef;
        box-sizing: border-box;
      }
    }
    .sbottom{
      width: 100%;
      .lines{
        // margin: 20px 0;
          display: flex;
      align-items: center;
      justify-content: flex-end;
          // border-bottom: 1px solid #ffffff33;
    padding-bottom: 10px;
    color: #9e9c9c;
      span:nth-child(2){
        color: #0af3ef;
        margin-left: 10px;
      }
      }
    }
      .imgs {
        height: 48px;
        width: 48px;
        margin: 10px;
      }
      input {
        border: none;
        background: transparent;
        height: 56px;
        flex: 1;
      }
      .max {
        width: 100px;
        height: 50px;
        background: #262626;
        border-radius: 10px;
        margin: 10px;
        color: #fff;
        text-align: center;
        line-height: 50px;
        font-size: 22px;
      }
    }








    .xztype{
        display: flex;
        align-items: center;
        justify-content: space-between;
       ::v-deep .van-stepper__input{
          font-size: 42px;
        }
        .tab{
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
              .tag{
       width: calc(30% - 20px);
       border: 3px solid #7C58D2;
       border-radius: 8px;
      font-size: 30px;
      font-family: $t-f;
      font-weight: 400;
      color: #ffffff;
      text-align: left;
      padding: 10px 10px;
      display: flex;
      background: #1b1d46;
      align-items: center;
      overflow: hidden;
      margin: 20px 10px ;
      justify-content: center;
          }
           .act{
               background:linear-gradient(315deg, #7C58D2 0%, #7C58D2 100%);
        // border-radius: 10px;
        color: #ffffff;
        }
        }
        .item{
        width: calc(100% - 200px);
      font-size: 30px;
      font-family: $t-f;
      font-weight: 400;
       color: #ffffff;
      text-align: left;
      padding: 0px 10px;
      display: flex;
      align-items: center;
      overflow: hidden;
      margin: 20px 10px 0;
      justify-content: center;
         white-space: nowrap;   
        }
      
    }
      .btn {
     width: calc(100% - 200px);
      height: 80px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 14px auto 54px;
background:linear-gradient(48deg, #3C2F64, #CE0A54);
box-shadow: 0px 4px 16px 4px rgb(10, 10, 10);
border-radius: 0px;
 border: 1px solid #ffffff; 
  display: flex;
      align-items: center;
      justify-content: center;
    }
    }
    .card3{
      .level{
      font-size: 28px;
        font-family: $t-mf;
        font-weight: 600;
        color:#ffffff;
        line-height: 40px;
        margin-top: 12px; 
      }
      .lname{
        font-size: 24px;
        font-family: $t-mf;
        font-weight: 600;
        color:#888888;
        line-height: 40px;
        margin-top: 12px;
      }
    }
}

</style>